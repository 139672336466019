.text-animate {
  display: inline-block;
  opacity: 0;
  animation: bounceIn 1s 1s;
  animation-fill-mode: forwards;
  min-width: 10px;
}

.use-effect-change-class-home {
  display: inline-block;
  min-width: 10px;
  animation-fill-mode: both;

  &:hover {
    animation: rubberBand 1s;
    color: #ffd700;
  }
}

.use-effect-change-class-about {
  min-width: 10px;
  display: inline-block;
  animation-fill-mode: both;

  &:hover {
    animation: rubberBand 1s;
    color: #fff;
  }
}
.use-effect-change-class-contact {
  min-width: 10px;
  display: inline-block;
  animation-fill-mode: both;

  &:hover {
    animation: rubberBand 1s;
    color: #fff;
  }
}
.use-effect-change-class-projects {
  min-width: 10px;
  display: inline-block;
  animation-fill-mode: both;

  &:hover {
    animation: rubberBand 1s;
    color: #fff;
  }
}

@for $i from 8 through 35 {
  ._#{$i} {
    animation-delay: #{calc($i/10)}s;
  }
}
