.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}
.projects-page {
  overflow-x: hidden;
  .text-zone {
    position: absolute;
    left: 90px;
    top: 15%;
    transform: translateY(-50%);
    width: 36%;
    vertical-align: middle;
  }
  h1 {
    color: #ffd700;
    font-size: 53px;
    margin-top: 0;
    font-family: 'Coolvetica';
    font-weight: 400;
    position: relative;
    margin-bottom: 40px;
    left: 10px;

    &::before {
      content: '<h1>';
      font-family: 'La Belle Aurore';
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -10px;
      left: 15px;
      opacity: 0.6;
    }
    &::after {
      content: '<h1/>';
      font-family: 'La Belle Aurore';
      display: block;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -5px;
      margin-left: 20px;
      opacity: 0.6;
      animation: fadeIn 1s 1.7s backwards;
    }
  }
}
.projects-container {
  //   padding: 15px 0 15px 15px;
  position: absolute;
  will-change: contents;
  left: 90px;
  right: 20px;
  top: 33%;
  //   background-color: #333;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  .project-box {
    background: #444;
    position: relative;
    flex: 1 1 20%;
    flex-direction: column;
    height: 50 0px;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    min-width: 271px;
    max-width: 330px;
    .image-wrap {
      position: relative;
      // width: 280px;
      height: 180px;
      .project-image {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 182px;
        // object-fit: unset;
        // width: 280px;
      }
    }
    .project-content {
      background: #1b262c;
      position: relative;
      width: inherit;
      height: 250px;
      z-index: 3;
      padding: 3px 3px;
      transition: cubic-bezier(0.165, 0.84, 0.44, 1);
      h4 {
        text-align: center;
        padding: 5px;
        font-size: 24px;
        height: 70px;
        margin: 0;
        color: #ffd700;
      }
      h5 {
        margin: 0;
        margin-top: 15px;
        color: #eeeeee;
        padding: 5px;
        font-size: 16px;
      }
      p {
        font-size: 14px;
        padding: 5px 5px 3px 5px;
        margin: 2px;
        color: #eeeeee;
        line-height: 18px;
        overflow: hidden;
      }
      .btn-project {
        position: absolute;
        padding: 2px 10px;
        left: 10px;
        height: 21px;
        margin: 5px;
        bottom: 2px;
        margin-left: 2px;
        border: 1px solid #ffd700;
        background-color: #ffd700;
        font-family: sans-serif;
        border-radius: 2px;
        cursor: pointer;
        &:hover {
          background-color: transparent;
          color: #999;
        }
      }
      .btn-code {
        position: absolute;
        right: 10px;
        padding: 2 15px;
        height: 21px;
        margin: 5px;
        bottom: 2px;
        margin-left: 2px;
        border: 1px solid #ffd700;
        background-color: #ffd700;
        font-family: sans-serif;
        border-radius: 2px;
        cursor: pointer;
        &:hover {
          background-color: transparent;
          color: #999;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .container.projects-page {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 50px;
    position: intial;
    .text-zone {
      width: 100%;
      position: initial;
      margin-left: 20px;
      margin-top: 20px;
    }
    .projects-container {
      width: 100%;
      position: initial;
      margin-top: 85px;
    }
    // .project-nav-bar {
    //   .skill-sm{

    //   }
    //   a {
    //     display: none;
    //   }
    // }
  }
}
