.page {
  width: 100%;
  height: 100%;
  position: absolute;
}
.top-right {
  position: fixed;
  float: right;
  right: 36px;
  bottom: auto;
  top: 30px;
  z-index: 999999999;
  .background-img {
    width: 40px;
    height: auto;
    opacity: 0.5;
  }
}
.top-tags {
  bottom: auto;
  top: 36px;
}
.bottom-tags {
  top: auto;
  bottom: 60px;
}
.bottom-tags-html {
  top: auto;
  bottom: 36px;
  margin-left: 36px;
}
.tags {
  color: #ffd700;
  opacity: 0.6;
  position: absolute;
  left: 120px;
  font-size: 18px;
  font-family: 'La Belle Aurore';
}
.bottom-tags-html {
  margin-left: 20px;
  bottom: 36px;
}

@media screen and (max-width: 1200px) {
  .page {
    position: initial;
    .top-right {
      position: absolute;
      top: 2px;
      left: 45%;
      right: 55%;
      bottom: auto;
      .background-img {
        width: 40px;
        height: auto;
        opacity: 0.5;
      }
    }
  }
  .container {
    position: initial;
    height: auto;
    min-height: auto;
    &.about-page,
    &.contact-page,
    &.portfolio-page {
      .text-zone {
        position: initial;
        margin-top: 50px;
        transform: none;
        width: 100%;
        display: block;
        padding: 20px;
        box-sizing: border-box;
      }
    }
  }
}
