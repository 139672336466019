.project-nav-bar {
  // background: #03051e;
  width: auto;
  height: auto;
  position: absolute;
  top: 23%;
  left: 90px;
  right: 20px;
  z-index: 3;
  display: flex;
  flex-wrap: wrap;
  .skill-nav-sm {
    display: none;
  }
  margin: auto;
  a {
    font-size: 17px;
    font-weight: 500;
    font-family: sans-serief;
    text-decoration: none;
    padding: 3px 18px;
    margin: 0 4px;
    white-space: nowrap;
    border: 1px solid #fff80a;
    color: #fff;
    position: relative;
    white-space: nowrap;
    animation: fadeInAnimation 1s 1.8s backwards;
    &:hover {
      border: 1px solid #fff80a;
      background: #fff80a;
      color: #0f044c;
    }
    &.active {
      color: rgb(0, 0, 0);
      background: #fff80a;
    }
  }
}
@media screen and (max-width: 1200px) {
  .container.projects-page .container.projects-container {
    margin-top: 50px;
  }
  .project-nav-bar {
    width: auto;
    height: auto;
    z-index: 5;
    position: inherit;
    margin: 0;
    left: 18px;
    top: 95px;
    box-sizing: border-box;
    .skill-nav-lg {
      display: flex;
      flex-wrap: wrap;
    }
  }
  // .project-nav-bar {
  //   width: auto;
  //   height: auto;
  //   z-index: 5;
  //   position: inherit;
  //   margin: 0;
  //   left: 18px;
  //   top: 95px;
  //   box-sizing: border-box;

  //   .skill-nav-lg {
  //     display: none;
  //   }
  //   .skill-nav-sm {
  //     background: #ddd;
  //     display: block;
  //     width: auto;
  //     .dropbtn {
  //       padding: 0 35px;
  //       background: #fff80a;
  //       border: 1px solid #fff80a;
  //       display: block;
  //       margin: auto;
  //       height: 40px;
  //       width: auto;
  //       .icon {
  //         margin-left: 15px;
  //       }
  //     }
  //     .dropdown-content {
  //       a {
  //         background: #0f044c;
  //         color: black;
  //         padding: 12px 16px;
  //         display: block;
  //         text-align: center;
  //         &:hover {
  //           background-color: #ddd;
  //         }
  //       }
  //     }
  //     .dropdown-none {
  //       position: initial;
  //       display: none;
  //     }
  //   }
  // }
}
