.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}
.home-page {
  .text-zone {
    position: absolute;
    left: 90px;
    top: 50%;
    transform: translateY(-50%);
    width: 70%;
  }
  h1 {
    color: #fff;
    font-size: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;
    &::before {
      content: '<h1>';
      font-family: 'La Belle Aurore';
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      left: 15px;
      opacity: 0.9;
    }
    &::after {
      content: '<h1/>';
      font-family: 'La Belle Aurore';
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.9;
    }
    img {
      width: 32px;
      height: auto;
      margin-left: 20px;
      margin-right: px;
      opacity: 0;
      animation: rotateIn 1s linear both;
      animation-delay: 1.4s;
    }
  }
  h2 {
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    font-family: 'sans-serief';
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }
  .flat-button {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4;
    font-family: sans-serief;
    text-decoration: none;
    padding: 10px 18px;
    margin-top: 25px;
    float: left;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;
    border: 1px solid #fff80a;
    background: #fff80a;
    color: #333;
    &:hover {
      border: 1px solid #fff80a;
      background: none;
      color: #fff;
    }
  }
}
@media screen and (max-width: 1200px) {
  .tags,
  .home-page h1::before,
  .home-page h1::after {
    display: none;
  }
  .home-page {
    .text-zone {
      position: inherit;
      width: 100%;
      left: 3%;
      top: 35%;
      // transform: none;
      padding: 10px;
      box-sizing: border-box;
    }
    .logo-container {
      // background-color: #8d8d8d;
      z-index: -1;
      position: absolute;
      width: 120px;
      height: auto;
      top: auto;
      right: 0;
      box-sizing: border-box;
      margin: auto;
      left: 0;
      .svg {
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
      }
    }
    .flat-button {
      float: none;
      display: block;
      margin: 20px auto 0 auto;
      width: 125px;
      text-align: center;
    }
  }
}
