// Global variable
$primary-colour: #ffd700;

// Import css file into main scss file
@import 'animate.css';
@import 'loaders.css';

// Add fonts
@font-face {
  font-family: 'Helvetica Neue';
  src: url(./assets/fonts/helvetica-neu.ttf) format('ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'La Belle Aurore';
  src: url(./assets/fonts/LaBelleAurore.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Coolvetica';
  src: url(./assets/fonts/CoolveticaRg-Regular.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}

input,
textarea {
  font-family: 'Helvetica Neue', sans-serif;
}
.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
}
.ball-clip-rotate-multiple > div {
  border: 2px solid #ffd700;
  border-bottom-color: transparent;
  border-top-color: transparent;
  &:last-child {
    border-color: #ffd700 transparent #ffd700 transparent;
  }
}
