html {
  /* 62.5% is x-small = 0.625em = 10px */
  font-size: 62.5%;
}

body {
  margin: 0;
  /* font weight = 300, font size = 11px, line height = 1.4, default font = Helvetica Neue, fall back font = sans-serif */
  font: 300 11px/1.4 'Helvetica Neue', 'sans-serif';
  color: #444;
  background-color: #11324D;
  overflow: hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width:1200px) {
  body {
    overflow: visible;
  }
}