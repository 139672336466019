.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}
.contact-page {
  .text-zone {
    position: absolute;
    left: 90px;
    top: 50%;
    transform: translateY(-50%);
    width: 36%;
    vertical-align: middle;
    display: table-cell;
  }
  h1 {
    color: #ffd700;
    font-size: 53px;
    margin-top: 0;
    font-family: 'Coolvetica';
    font-weight: 400;
    position: relative;
    margin-bottom: 40px;
    left: 10px;

    &::before {
      content: '<h1>';
      font-family: 'La Belle Aurore';
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -20px;
      left: 15px;
      opacity: 0.6;
    }
    &::after {
      content: '<h1/>';
      font-family: 'La Belle Aurore';
      display: block;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: 5px;
      margin-left: 20px;
      opacity: 0.6;
      animation: fadeIn 1s 1.7s backwards;
    }
  }
  p {
    color: #fff;
    font-weight: 100;
    font-size: 13px;
    font-family: sans-serif;
    min-width: fit-content;
    letter-spacing: 2px;
    animation: pulse 1s;
    &:nth-of-type(1) {
      animation-delay: 1.1s;
    }
    &:nth-of-type(2) {
      animation-delay: 1.4s;
    }
    &:nth-of-type(3) {
      animation-delay: 1.7s;
    }
  }
}
.contact-form {
  width: 100%;
  margin-top: 20px;
  ul {
    padding: 0;
    margin: 0;
    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      overflow: hidden;
      display: block;
      position: relative;
      opacity: 0;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
      clear: both;
    }
    li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0;
    background: #ffd700;
    opacity: 1;
    height: 40px;
    font-size: 16px;
    color: #333;
    padding: 0 10px;
    box-sizing: border-box;
    &::placeholder {
      color: #777;
    }
  }
  textarea {
    width: 100%;
    border: 0;
    background: #ffd700;
    height: 50px;
    font-size: 16px;
    color: #333;
    padding: 10px;
    min-height: 60px;
    box-sizing: border-box;
    &::placeholder {
      color: #777;
    }
  }
  .flat-button {
    color: #ffd700;
    background: 0 0;
    font: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #ffd700;
    float: right;
    border-radius: 4px;
    &:hover {
      background-color: #ffd700;
      color: #0a042b;
      cursor: pointer;
    }
  }
}
.map-canvas {
  position: absolute;
  background-color: #000;
  opacity: 0.8;
  top: 45px;
  right: 15px;
  z-index: 999999;
  width: 267px;
  padding: 20px;
  color: #fff;
  font-family: 'Helvetica';
  font-size: 17px;
  font-weight: 300;
  animation: fadeIn 1s 1.5s;
  animation-fill-mode: forwards;

  span {
    font-size: 16px;
    display: block;
    padding-top: 20px;
    color: #ffd700;
    .link-meeting {
      color: #333;
      background: #ffd700;
      font: 11px;
      letter-spacing: 3px;
      text-decoration: none;
      padding: 8px 10px;
      border: 1px solid #ffd700;
      float: left;
      border-radius: 4px;
      &:hover {
        background-color: transparent;
        color: #ffd700;
        cursor: pointer;
      }
    }
  }
}
.map-wrap {
  background: rgba(8, 253, 216, 0.1);
  float: right;
  width: 53%;
  height: 100%;
}
.leaflet-container {
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0;
  animation: backInRight 1s 1.2s;
  animation-fill-mode: forwards;
  .leaflet-popup-content {
    font-size: 15px;
  }
}
.email-status {
  position: absolute;
  top: 95%;
  left: 32%;
}

@media screen and (max-width: 1200px) {
  .container {
    position: initial;
    margin: 20px auto;
  }
  .map-wrap {
    float: none;
    width: 100%;
    height: 400px;
    margin: 0;
  }
  .map-canvas {
    display: none;
  }
  .email-status {
    position: relative;
    padding-right: 10px;
  }
}
